import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// get other libraries
import 'slick-carousel';
import 'jquery-ui/ui/widgets/datepicker';
import 'lightbox2/src/js/lightbox';

// initialize foundation
$(document).foundation();

// and the magic starts

// console log handsome message
const styles = ['color: black', 'background: yellow', 'padding: 25px', 'font-size: 24px', 'font-family: "futura-pt"'].join(';');
const message = 'Developed with 🖤 by www.whitehat.gr';
console.log('%c%s', styles, message);

// Prevent small screen page refresh sticky bug

// $(window).on('sticky.zf.unstuckfrom:bottom', function(e) {
//   if (!Foundation.MediaQuery.atLeast('medium')) {
//     $(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
//   }
// });

// listen when sticky
const logo = document.querySelector('.js-logotype');
$(window).on('sticky.zf.stuckto:top', function(e) {
  logo.src = PASSEDVAR.themeDirectory + '/images/ac-logo-black.svg';
});
$(window).on('sticky.zf.unstuckfrom:top', function(e) {
  logo.src = PASSEDVAR.themeDirectory + '/images/ac-logo.svg';
});

// listen when nav accordion is open
$(window).on('down.zf.accordionMenu', function(e) {
  const allChildren = e.target.children;
  for (const child of allChildren) {
    if(child.hasAttribute('aria-expanded') && child.attributes['aria-expanded'].value){
      const expandedChildren = child.children[1].children;
      var timeout = 0;
      for (const child of expandedChildren) {
        setTimeout(function(){
          child.classList.add('open');
        }, timeout);
        var timeout = timeout + 90;
      }
    }
  }
});

// listen when nav accordion is closed
$(window).on('up.zf.accordionMenu', function(e) {
  const allChildren = e.target.children;
  for (const child of allChildren) {
    if(child.hasAttribute('aria-expanded') && child.attributes['aria-expanded'].value){
      const expandedChildren = child.children[1].children;
      var timeout = 0;
      for (const child of expandedChildren) {
        child.classList.remove('open');
      }
    }
  }
});

// trigger menu open
var menuTrigger = document.querySelector('.js-menuTrigger');
menuTrigger.addEventListener('click', openMenu);
menuTrigger.addEventListener('click', checkIfOpen);

function checkIfOpen(){
    if(!this.classList.contains('clickedHamburger')){
        enableScroll();
    } else {
        disableScroll();
    }
}

// the menu open function
function openMenu() {
    var menuElements = document.querySelectorAll('.js-animatedOffcanvasItems');
    var triggerElements = document.querySelectorAll('.js-menuLine');
    var upperHeaderContainer = document.querySelector('.js-stickyHeaderPartContainer');
    this.classList.toggle('clickedHamburger');
    if( upperHeaderContainer.classList.contains('clickedHamburger')) {
      $('.js-accordionMenu').foundation('hideAll');
      upperHeaderContainer.classList.add('closing');
      triggerElements.forEach( menuElement => menuElement.classList.add('closing'));
      setTimeout(function(){
        upperHeaderContainer.classList.remove('closing');
        triggerElements.forEach( menuElement => menuElement.classList.remove('closing'));
      }, 1100);
    };
    upperHeaderContainer.classList.toggle('clickedHamburger');
    menuElements.forEach( menuElement => menuElement.classList.toggle('opened'));
    triggerElements.forEach( triggerElement => triggerElement.classList.toggle('clickedHamburger'));
}

// disables/enables scroll

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  // window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  // window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  // window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  // window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  document.getElementsByTagName('body')[0].style.overflowY = "hidden";
}

// call this to Enable
function enableScroll() {
  // window.removeEventListener('DOMMouseScroll', preventDefault, false);
  // window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  // window.removeEventListener('touchmove', preventDefault, wheelOpt);
  // window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  document.getElementsByTagName('body')[0].style.overflowY = "auto";
  document.getElementsByTagName('body')[0].style.position = "static";
}

/*--------------------|
|                     |
| CHECK AVAILABILITY  |
|                     |
|--------------------*/

$('.js-check-availability').on('submit', function () {
  var formData = this;
  var parsedForm = $('.js-check-availability').serialize();
  window.open("https://agnandicielo.reserve-online.net/?"+parsedForm, '_blank');
  return false;
});

/*--------------------|
|                     |
|       SLIDER        |
|                     |
|--------------------*/

// add settings for the carousel
if ( $( ".heroCarousel" ).length ) {

$('.heroCarousel').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 4000
});

createCustomBullets('.heroCarousel', '.carouselNavigation', '#carouselNavigationButtonTemplate');

}

/*--- END OF SLIDER ---*/

/*---------------------|
|                      |
|    rooms sliders     |
|                      |
|---------------------*/

// add settings for the room slider 1
if ( $( ".room__slider--1" ).length ) {

  $('.room__slider--1').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--1', '.js-buttonPrevRoom--1', '.js-buttonNextRoom--1');
}

if ( $( ".room__slider--2" ).length ) {

  $('.room__slider--2').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--2', '.js-buttonPrevRoom--2', '.js-buttonNextRoom--2');
}

if ( $( ".room__slider--3" ).length ) {

  $('.room__slider--3').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--3', '.js-buttonPrevRoom--3', '.js-buttonNextRoom--3');
}

if ( $( ".room__slider--4" ).length ) {

  $('.room__slider--4').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--4', '.js-buttonPrevRoom--4', '.js-buttonNextRoom--4');
}

if ( $( ".room__slider--5" ).length ) {

  $('.room__slider--5').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--5', '.js-buttonPrevRoom--5', '.js-buttonNextRoom--5');
}

if ( $( ".room__slider--6" ).length ) {

  $('.room__slider--6').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--6', '.js-buttonPrevRoom--6', '.js-buttonNextRoom--6');
}

if ( $( ".room__slider--amenities" ).length ) {

  $('.room__slider--amenities').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--amenities', '.js-buttonPrevRoom--amenities', '.js-buttonNextRoom--amenities');
}

if ( $( ".room__slider--facilities" ).length ) {

  $('.room__slider--facilities').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  createCustomNav('.room__slider--facilities', '.js-buttonPrevRoom--facilities', '.js-buttonNextRoom--facilities');
}

/*------------------------|
|                         |
|   testimonials slider   |
|                         |
|------------------------*/

// add settings for the testimonials slider
if ( $( ".testimonial__slider" ).length ) {

  $('.testimonial__slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000
  });

  // add custom nav for testimonials slider
  createCustomNav('.testimonial__slider', '.js-buttonPrevTestimonial', '.js-buttonNextTestimonial');
  // add custom bullets for testimonials slider
  createCustomBullets('.testimonial__slider', '.testimonialNavigation', '#testimonialNavigationButtonTemplate');
}

/*------------------------|
|                         |
|   highlights slider     |
|                         |
|------------------------*/

// add settings for the testimonials slider
if ( $( ".highlights__carousel" ).length ) {

  $('.highlights__carousel').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1
  });

  // add custom nav for testimonials slider
  createCustomNav('.highlights__carousel', '.js-buttonPrev', '.js-buttonNext');
}

/*------------------------|
|                         |
|      posts slider       |
|                         |
|------------------------*/

// add settings for the carousel
if ( $( ".posts__carousel--beaches" ).length ) {

  $('.posts__carousel--beaches').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 800,
      fade: true,
      cssEase: 'linear',
      autoplay: false,
      autoplaySpeed: 4000
  });

  // adds custom bullets
  createCustomBullets('.posts__carousel--beaches', '.postsNavigation--beaches', '#postsNavigationButtonTemplate--beaches');
}

/*--------------------------------|
|                                 |
|   posts slider (attractions)   |
|                                 |
|--------------------------------*/

// add settings for the carousel
if ( $( ".posts__carousel--attractions" ).length ) {
  $('.posts__carousel--attractions').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 800,
      fade: true,
      cssEase: 'linear',
      autoplay: false,
      autoplaySpeed: 4000
  });

  // adds custom bullets
  createCustomBullets('.posts__carousel--attractions', '.postsNavigation--attractions', '#postsNavigationButtonTemplate--attractions');
}

// datepicker
$( "#datepicker" ).datepicker({
  dateFormat: "yy-mm-dd",
  minDate: 0
});

// show booking info on contact form when reservation info checked
$(".js-reservation").on('click', function(){
  var reservationCheckbox = $('.js-reservation input:checked').length;
  console.log(reservationCheckbox);
  if(reservationCheckbox) {
    $(".js-bookingDetails").addClass('open');
  } else {
    $(".js-bookingDetails").removeClass('open');
  }
})


// show native or jquery datepicker

// if(!checkDateInput()) {
//   document.querySelector('#datepicker').classList.remove('hide');
//   console.log('hi');
// }

/*--------------------------------|
|                                 |
|    custom slider for moments    |
|                                 |
|--------------------------------*/



var looper = 0;
$('.js-buttonNext').on('click', function(){

  $('.carouselItemHolder').each(function(){
    var slice = this;
    var currentNo = parseInt($(slice).attr("data-slideNo"));

      $("body").queue(function(next){
        $(slice).addClass('slideNext');
        if(currentNo == '5'){
          $(slice).attr("data-slideNo", "1");
        } else {
          $(slice).attr("data-slideNo", currentNo+1);
        };
        setTimeout(function(){ $(slice).removeClass('slideNext') }, 400);
        next();
      }).delay(100);
    });

});

var backwardLooper = 0;
$('.js-buttonPrev').on('click', function(){

    $('.carouselItemHolder').each(function(){
      var slice = this;
      var currentNo = parseInt($(slice).attr("data-slideNo"));

      $("body").queue(function(next){
        $(slice).addClass('slidePrev');
        if(currentNo == '1'){
          $(slice).attr("data-slideNo", "5");
        } else {
          $(slice).attr("data-slideNo", currentNo-1);
        };
        setTimeout(function(){ $(slice).removeClass('slidePrev') }, 600);
        next();
      }).delay(100);
    });

});

/*--------------------------------|
|                                 |
|  function for custom prev/next  |
|                                 |
|--------------------------------*/

function createCustomNav(slider, prevBtnClass, nextBtnClass) {
  $(nextBtnClass).on('click', function(){
    $(slider).slick('slickNext');
  })
  $(prevBtnClass).on('click', function(){
    $(slider).slick('slickPrev');
  });
}

/*--------------------------------|
|                                 |
|   function for custom bullets   |
|                                 |
|--------------------------------*/

function createCustomBullets(slider, navigationContainer, bulletsTemplate) {
  // get all images from main slider
  const sliderItems = document.querySelector(slider).slick.$slides;
  // const sliderItemsSrc = [];
  // for (let item = 0; item<sliderItems.length; item++) {
  //     sliderItemsSrc.push(sliderItems[item].children[0].children[0].children[0].src);
  // }

  // construct slideshow thumbnails (basically just a custom navigation)
  const sliderItemsCount = document.querySelector(slider).slick.slideCount;
  const sliderThumbnailsContainer = document.querySelector(navigationContainer);
  const sliderThumbnailsTemplate = document.querySelector(bulletsTemplate);
  for (let sliderItem = 0; sliderItem < sliderItemsCount; sliderItem++) {
    const clone = sliderThumbnailsTemplate.content.cloneNode(true);
    const sliderThumbnailsInput = clone.querySelector('.postsNavigationButtonHolder');
    sliderThumbnailsInput.setAttribute("id", "slick-slide-control"+sliderItem+1);
    sliderThumbnailsInput.setAttribute("aria-controls", "slick-slide"+sliderItem+1);
    sliderThumbnailsInput.setAttribute("aria-label", sliderItem+1+" of "+sliderItemsCount);
    sliderThumbnailsInput.setAttribute("data-slide-target", sliderItem);
    sliderThumbnailsContainer.appendChild(clone);
  }

  // Get all buttons
  const sliderInputs = sliderThumbnailsContainer.querySelectorAll('.postsNavigationButtonHolder');

  // Create a function to change the slide on click 
  function changeSlide(){
    const slideToShow = this.dataset.slideTarget;
    document.querySelector(slider).slick.slickGoTo(slideToShow);
    const allSliderButtons = sliderThumbnailsContainer.querySelectorAll('.postsNavigationButtonHolder');
    allSliderButtons.forEach(sliderButton => sliderButton.classList.remove('active'));
    this.classList.add('active');
  }

  // Change slide on click
  sliderInputs.forEach(element => element.addEventListener("click", changeSlide));

  // Do this to get the first active slide
  const allSliderButtons = sliderThumbnailsContainer.querySelectorAll('.postsNavigationButtonHolder');
  allSliderButtons.forEach( function(sliderButton) {
      var eachSlideButton = sliderButton.dataset.slideTarget;
      var currentSlideItem = document.querySelector(slider).slick.currentSlide;
      if(eachSlideButton == currentSlideItem) {
          sliderButton.classList.add('active');
      }
  });

  // On before slide change (this is needed for autorotation)
  $(slider).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    const allSliderButtons = sliderThumbnailsContainer.querySelectorAll('.postsNavigationButtonHolder');
    allSliderButtons.forEach( function(sliderButton) {
        var eachSlideButton = sliderButton.dataset.slideTarget;
        if(eachSlideButton == nextSlide) {
            sliderButton.classList.add('active');
        } else {
            sliderButton.classList.remove('active');
        }
    });
  });
}

/*--- END OF SLIDER ---*/

// chcks to see if input with a type of date is available
function checkDateInput() {
  var input = document.createElement('input');
  input.setAttribute('type','date');

  var notADateValue = 'not-a-date';
  input.setAttribute('value', notADateValue); 

  return (input.value !== notADateValue);
}

// custom cursor
let mouseCursor = document.querySelector(".customCursor");
let lis = document.querySelectorAll(".galleryItem--room");

lis.forEach(function(listItem){
  listItem.addEventListener("mouseover", function(){
    mouseCursor.classList.remove('hidden');
    document.body.style.cursor = 'none';
    listItem.addEventListener("mousemove", cursor);
  });
  listItem.addEventListener("mouseout", function(){
    mouseCursor.classList.add('hidden');
    document.body.style.cursor = 'auto';
  });
})

function cursor(e) {
  mouseCursor.style.top = e.pageY + "px";
  mouseCursor.style.left = e.pageX + "px";
}

// change gallery section
$(document).on('click', '.navigation__anchor', function() {
  $('.navigation__anchor').removeClass('active');
  $(this).addClass('active');
  $.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    dataType: 'html',
    data: {
      action: 'wh_filter_gallery',
      postID: $(this).data('post-id')
    },
    success: function(res) {
      $('.js-galleryContainer').html(res);
    }
  });
});

// close covid notification
var closeBtn = document.querySelector('.js-closeCovid');
var toBeClosed = document.querySelector('.js-toBeClosed');
var containerElement = document.querySelector('.heroHeaderHolder');
var stickyNav = document.querySelector('.js-stickyHeaderPartContainer');

closeBtn.addEventListener("click", function() {
  toBeClosed.classList.remove('open');
  containerElement.classList.remove('openCovid');
  stickyNav.classList.remove('moveDown');
  setCookie('covidNotification', '1');
});

function checkCovidNotification(){
  if(getCookie('covidNotification') !== '1') {
    toBeClosed.classList.add('open');
    containerElement.classList.add('openCovid');
    stickyNav.classList.add('moveDown');
  }
}

checkCovidNotification();

// set cookie function
function setCookie(cname, cvalue) {
  document.cookie = cname + "=" + cvalue + ";" + "path=/";
}

// read cookie function
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const consentTrigger = document.getElementById('cookie__consent--info-trigger');
if(consentTrigger !== null) {
  consentTrigger.addEventListener('click', function(){
    CookieConsent.show();
  });
}